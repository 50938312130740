.zhuyinWord {
  direction: rtl;
  font-family: cursive;
  overflow: hidden;

  .content {
    display: flex;
    width: 100%;
    height: 90%;
    overflow: hidden;

    div {
      margin: 0 3vmin;

      &:first-child {
        margin-right: 0;
      }
    }

    ruby {
      rt {
        font-family: BopomofoRuby;
        font-size: 5vmin;
        writing-mode: vertical-rl;
      }

      .activeRt {
        color: #f00;
      }
    }

    .label {
      font-weight: 700;
      font-size: 15vmin;
      margin-right: 0;
      direction: ltr;
      writing-mode: vertical-rl;
    }

    .word {
      writing-mode: vertical-rl;
      direction: ltr;

      ruby {
        margin: 2vmin 0;
      }
    }

    .img {
      width: 30%;
      min-width: 30%;
      max-width: 30%;
      height: 100%;
      text-align: center;
      overflow: hidden;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .sentence {
      display: flex;
      flex-wrap: wrap;
      direction: ltr;
      writing-mode: vertical-rl;
      padding: 0 1.75vmin;
      height: 100%;

      ruby {
        margin: 2.5vmin 0;
        white-space: nowrap;
        backface-visibility: hidden;
      }
    }
  }

  .tabBox {
    display: flex;
    align-items: center;
    direction: ltr;
    height: 10%;
  }
}