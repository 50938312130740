.paiquizMask {
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: absolute;
  z-index: 99999;
}

.boxModal {
  position: fixed;
  display: inline-flex;
  flex-direction: column;
  background-color: #51464c;
  box-shadow: 2px 2px 10px #333;
  border-radius: 20px;
  overflow: hidden;
  top: 50%;
  left: 50%;
  z-index: 100000;
  transform: translate(-50%, -50%) !important ;
  width: 650px;
  @media screen and (max-width: 600px) {
    width: 90vw; /* 在小于等于 600px 宽度时更新 CSS 变量的值 */
  }

  @media screen and (min-width: 1200px) {
    width: 636px; /* 在大于等于 1200px 宽度时更新 CSS 变量的值 */
  }
  .content {
    overflow: hidden;
    background: #000000;
  }

  .bottom {
    padding: 3%;
    button {
      border: none;
      appearance: none;
      font-size: 15px;
      font-weight: 700;
      color: white;
      white-space: nowrap;
      cursor: pointer;
      &.confirmClose {
        width: 151px;
        padding: 11px 45px;
        border: 1px solid white;
        border-radius: 8px;
        background: transparent;
        margin-right: 40px;
        margin-left: 99px;
      }
      &.goBack {
        width: 200px;
        padding: 11px 62px;
        border-radius: 8px;
        background: #EC7963;
        margin-right: 99px;
      }
    }
  }

  .controlHead {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2% 2%;
    width: 100%;
    cursor: move;
    h1, .title {
      color: white;
      font-size: 24px;
      font-weight: 500;
      margin: 0 auto;
    }

    .closeButton {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      height: 27px;
      border-radius: 12px;
      box-shadow: 1px 1px 5px #333;
      background-color: #fff;
      font-size: 3.5vmin;
      color: #ec6464;
      cursor: pointer;
    }
  }
  .confirmDialog {
    font-size: 24px;
    font-weight: 400;
    padding: 24px;
    color: white;
    width: 640px;
    text-align: center;
    span {
      color: #EC7963;
    }
  }
  .oneLinkTitle {
    font-size: 32px;
    font-weight: 500;
    color: white;
    width: 510px;
    margin-top: 0;
    margin-bottom: 12px;
    text-align: center;
    img {
      height: 33px;
      vertical-align: middle;
      margin-right: 11px;
    }
  }
  .oneLinkDialog {
    color: white;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    text-align: center;
  }
  .oneLinkLink {
    display: block;
    color: #EC7963;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    margin: 0 auto 16px;
    &.forElectron {
      width: 120px;
      color: #EC7963;
      background: transparent;
    }
  }
}