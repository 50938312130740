.originalContent {
  display: flex;
  padding: 32px 60px;
  justify-content: center;

  .immediatePai,
  .oneLinkPai {
    background: white;
    border-radius: 8px;
    width: 248px;
    height: 185px;
    text-align: center;
    padding: 53px 6px 44px;
    position: relative;
    cursor: pointer;

    &.choose {
      border: 4px solid #EC7963;
      color: #EC7963;
    }

    h2 {
      display: inline-block;
      font-size: 24px;
      font-weight: 500;
      color: #454B5C;
      margin: 0 0 8px;
    }

    p {
      font-size: 16px;
      font-weight: 400px;
      color: #A1A4B1;
      margin: 0;
    }

    img {
      max-width: 100%;
      height: 25px;
    }
  }

  // .immediatePai {
  //   margin-right: 24px;
  // }
}

%bottomWhiteButton {
  width: 150px;
  appearance: none;
  background: transparent;
  border: 1px solid white !important;
  border-radius: 8px;

  img {
    vertical-align: text-bottom;
    margin-left: 12px;
  }
}

.originalBottom {
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  button {
    @extend %bottomWhiteButton
  }
}

.noAccessBottom {
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  button {
    @extend %bottomWhiteButton
  }
}

.immediateContent {
  color: white;
  padding: 2% 3%;

  .qrArea {
    display: flex;
    align-items: center;

    .text1 {
      color: #EC7963;
      font-size: 24px;
      font-weight: 500;
    }

    .qrText {
      font-size: 20px;
      font-weight: 400;
    }

    .qrCode {
      background: white;
      padding: 12px;
      border-radius: 8px;
      margin-right: 24px;
      max-width: 100%; /* 设置最大宽度为屏幕宽度 */
      height: auto; /* 保持宽高比例 */
      margin: auto; /* 居中显示 */
    }
  }

  .linkArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    height: 56px;
    border-radius: 16px;
    padding: 16px 24px;
    background: #51464C;
    font-size: 20px;
    font-weight: 600;

    button {
      background: transparent;
      padding: 0;
      appearance: none;
      border: none;
      width: initial;
      height: initial;
      cursor: pointer;

      img {
        vertical-align: middle;
      }
    }
  }
}

.immediateBottom {
  text-align: center;

  .resultLink {
    width: 150px;
    white-space: nowrap;
    padding: 11px 45px;
    background: #EC7963;
    border-radius: 8px;
    border: none;
    font-size: 15px;
    font-weight: 700;
    color: white;
    text-decoration: none;
    &.forElectron {
      padding: 0;
    }
  }
}

.oneLinkContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 32px 40px 0px;
  min-width: 640px;
  height: 338px;
  overflow-y: auto;
  position: relative;
  &.loading {
    align-items: center;
  }

  &.freeze {
    overflow: hidden;
  }

  .class {
    display: flex;
    align-items: center;
    color: white;
    font-size: 18px;
    font-weight: 500;
    width: 47%;
    margin-bottom: 32px;
    cursor: pointer;

    img {
      height: 70px;
      margin-right: 11px;
    }

    p {
      margin: 0;
    }
  }
}

.oneLinkBottom {
  text-align: center;

  button {
    width: 220px;
    padding: 11px 70px;
    border: none;
    appearance: none;
    border-radius: 8px;
    background: #EC7963;

    &.disable {
      cursor: not-allowed !important;
      user-select: none;
      color: #A1A4B1;
      background: #D5D7DE;
    }
  }
}

.chooseQuizContent {
  display: flex;
  padding: 32px 60px;
  overflow-y: auto;

  .chooseQuiz {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 157px;
    height: 185px;
    background: white;
    border-radius: 8px;
    position: relative;
    padding: 32px 22px;
    text-align: center;
    color: #454B5C;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;

    &:nth-child(1) {
      margin: 0px 24px 0px 0px;
    }

    &:nth-child(3) {
      margin: 0px 0px 0px 24px;
    }

    &.choose {
      border: 4px solid #EC7963;
      color: #EC7963;
    }
  }

  .quizIcon {
    width: 112px;
    margin-bottom: 8px;
  }
}

.chooseIcon {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 26px;

  &.choose {
    display: inline;
  }
}

.noAccess {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 49px 0px;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  
  .noAccessText {
    color: white;
    font-weight: 400;
    font-size: 20px;
    margin: 12px 0px;
    img {
      height: 25px;
      vertical-align: text-bottom;
      margin-right: 12px;
    }
  }

  a {
    color: white;
  }

  &.show {
    display: block;
  }
}

.empty {
  color: white;
  width: 500px;
  height: 185px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}